import React from 'react'
import ReactDOM from 'react-dom'
import ErrorFrame from './components/ErrorFrame'
import toast from './utils/toast'

let domNode

function handleError(e) {
  if (e instanceof ErrorEvent) {
    e = e.error
  }
  let crash = shouldRenderError()
  if (crash) {
    renderError(e)
  }
}

function shouldRenderError() {
  return !domNode.innerHTML
}

function renderError(e) {
  ReactDOM.render(<ErrorFrame err={e} />, domNode)
}

export default function setUp(errorDomNode) {
  domNode = errorDomNode
  window.onerror = handleError
  window.addEventListener('error', handleError)
  window.addEventListener('unhandledrejection', e => {
    let { reason } = e
    if (!(reason instanceof Error)) {
      reason = new Error('Unhandled Promise Rejection: ' + reason)
    }
    handleError(reason)
    toast.error({
      title: 'Rejection',
      message: reason.message
    })
  })
}
