import React, { Component } from 'react'

class ErrorFrame extends Component {
  render() {
    const { err } = this.props

    return (
      <div className="ErrorFrame" id="outer-container">
        <div className="card">
          <div className="card-header">
            Oops! Es ist ein Fehler aufgetreten.
          </div>
          <div className="card-block">
            <strong>{err ? err.toString() : 'Unbekannt'}</strong>
            <br />
            {err && <pre>{err.stack}</pre>}
          </div>
        </div>
      </div>
    )
  }
}

export default ErrorFrame
