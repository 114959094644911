import { store } from '../index'
import localForage from 'localforage'

export const cleanup = () => {
  const stateData = store.getState()

  const preserveKeys = ['image_00000000-0000-0000-0000-000000000000']

  const preserveReports = [stateData.currentReport].concat(
    Object.values(stateData.reports.drafts)
  )

  preserveReports.forEach(report => {
    if (!report) {
      return
    }
    Object.values(report.steps).forEach(stepContents =>
      stepContents.forEach(stepEntry => {
        if (stepEntry.imageId) {
          preserveKeys.push('image_' + stepEntry.imageId)
        }
      })
    )
  })

  localForage.keys().then(keys =>
    keys.forEach(key => {
      if (key.startsWith('image_') && preserveKeys.indexOf(key) === -1) {
        localForage.removeItem(key).then(() => console.log('Removed ' + key))
      }
    })
  )
}
