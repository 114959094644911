import { isEmpty } from './currentReport'

const initialState = { drafts: {}, server: {} }

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SAVE_DRAFT':
      if (!isEmpty(action.report) && !action.report.isSyncWithServer) {
        state.drafts = Object.assign({}, state.drafts, {
          [action.report.guid]: action.report
        })
        return Object.assign({}, state)
      }
      return state
    case 'DELETE_DRAFT':
    case 'SUBMIT_OK':
      let newDrafts = Object.assign({}, state.drafts)
      delete newDrafts[action.report.guid]
      state.drafts = newDrafts
      return Object.assign({}, state)
    case 'RECEIVE_SERVER_REPORTS':
      return Object.assign({}, state, {
        server: transformServerDrafts(action.data, action.user)
      })
    default:
      return state
  }
}

const transformServerDrafts = (data, user) => {
  const now = new Date()
  return data
    .sort((a, b) => {
      let dateA = new Date(a.updated_at)
      let dateB = new Date(b.updated_at)
      if (dateA > dateB) {
        return -1
      }
      if (dateB > dateA) {
        return 1
      }
      return 0
    })
    .map(report => {
      let steps = {}
      report.steps.forEach(step => {
        // TODO: Make sure this is ordered by pos
        if (!(step.step_id in steps)) {
          steps[step.step_id] = []
        }
        steps[step.step_id].push({
          dbId: step.id,
          comment: step.comment_1,
          security_hash: step.security_hash
        })
      })

      let udate = new Date(report.updated_at.replace(/-/g, '/'))

      return {
        guid: report.id,
        employee: user.employees.find(emp => emp.id === report.employee_id),
        title: report.mark,
        insurance: report.insurance,
        accident_opponent: report.accident_opponent,
        status: report.status,
        isSyncWithServer: now,
        date: udate,
        repairDone: report.repairDone,
        daysOld: (new Date() - udate) / 86400000,
        steps
      }
    })
}
