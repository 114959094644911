export const template = {
  title: 'Neuer Auftrag',
  steps: {}
  // submitted: false,
  // id: null
}
const initialState = null

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'CLOSE_REPORT':
      return null
    case 'SET_REPORT':
      return Object.assign({}, action.report)
    case 'SUBMIT_OK':
      return Object.assign({}, state, { isSyncWithServer: new Date() })
    default:
      return state
  }
}

export const isEmpty = report => {
  return (
    report.title === template.title && Object.keys(report.steps).length === 0
  )
}
