import { store } from '../index'
import { callApiChecked } from '../middleware/api'
import localForage from 'localforage'
import toast from '../utils/toast'

const UPLOAD_CONCURRENCY = 3

const submit = cb => {
  const report = Object.assign({}, store.getState().currentReport)

  // Local drafts have a GUID, once on the server there is an numeric ID
  const needToCreate = typeof report.guid !== 'number'
  let method, url
  if (needToCreate) {
    method = 'POST'
    url = 'assessment'
  } else {
    method = 'PUT'
    url = 'assessment/' + report.guid
  }

  return callApiChecked(url, {
    method,
    body: JSON.stringify(baseDataPayload(report))
  }).then(result => {
    //(new Promise((res, rej) => { res()})).then(result => {
    report.guid = result.id
    if (needToCreate) {
      store.dispatch({ type: 'SET_REPORT', report })
    }

    // Bild mit ServerID: Nur Text updaten
    let imagePromises = []
    let imagesToUpload = []
    Object.keys(report.steps).forEach(step_id => {
      const stepContents = report.steps[step_id]
      stepContents.forEach((stepEntry, i) => {
        stepEntry = Object.assign({}, stepEntry)
        const needToCreateStep = !stepEntry.dbId
        let stepApiUrl = 'assessment/' + report.guid + '/steps'
        let body = {
          comment_1: stepEntry.comment ? stepEntry.comment : '',
          position: i,
          step_id
        }
        cb('queueImage')
        if (needToCreateStep) {
          imagesToUpload.push(() => {
            return getImagePayload(stepEntry.imageId)
              .then(getBase64FromBlob)
              .then(b64Str => {
                body.image = b64Str
                let req = callApiChecked(stepApiUrl, {
                  method: 'POST',
                  body: JSON.stringify(body)
                })
                b64Str = null
                delete body.image
                return req
              })
              .then(response => {
                cb('finishImage')
                stepEntry.dbId = response.id
                report.steps[step_id][i] = stepEntry
                store.dispatch({ type: 'SET_REPORT', report })
                return response
              })
              .catch(e => {
                cb('imgErr')
                if (!e.warned) {
                  toast.error({
                    title: 'Image Upload Error',
                    message: e.message
                  })
                }
              })
          })
        } else {
          let req = callApiChecked(stepApiUrl + '/' + stepEntry.dbId, {
            method: 'PUT',
            body: JSON.stringify(body)
          })
          imagePromises.push(req)
          req
            .then(res => {
              cb('finishImage')
            })
            .catch(e => {
              cb('imgErr')
              if (!e.warned) {
                toast.error({
                  title: 'Image Update Error',
                  message: e.message
                })
              }
            })
        }
      })
    })

    let queueAllImages = new Promise(resolve => {
      let requestsRunning = 0
      let queueImages = () => {
        while (requestsRunning < UPLOAD_CONCURRENCY) {
          if (imagesToUpload.length === 0) {
            console.log('All image uploads queued')
            resolve()
            break
          }
          let startUpload = imagesToUpload.shift()
          let imageUploadPromise = startUpload()
          requestsRunning++
          imagePromises.push(imageUploadPromise)
          imageUploadPromise.then(() => {
            requestsRunning--
            queueImages()
          })
        }
      }
      queueImages()
    })

    return queueAllImages
      .then(() => Promise.all(imagePromises))
      .then(() => {
        const reportAfterAllStepsSaved = store.getState().currentReport
        let steps = []
        Object.values(reportAfterAllStepsSaved.steps).forEach(stepArray =>
          stepArray.forEach(step => {
            steps.push(step.dbId)
          })
        )
        return callApiChecked('assessment/' + reportAfterAllStepsSaved.guid, {
          method: 'PUT',
          body: JSON.stringify({ steps })
        })
      })
  })
}

function getImagePayload(imageId) {
  return localForage.getItem('image_' + imageId).then(file => {
    if (!file) {
      throw new Error('Image ' + imageId + ' not found')
    }
    return file
  })
}

function getBase64FromBlob(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

const baseDataPayload = report => {
  return {
    mark: report.title,
    insurance: report.insurance,
    repairDone: report.repairDone,
    accident_opponent: report.accident_opponent
      ? report.accident_opponent
      : null,
    employee_id: Number(report.employee ? report.employee.id : 0),
    contact_info: report.contact_info ? report.contact_info : null
  }
}

export default submit
