import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router } from 'react-router-dom'

import { Provider } from 'react-redux'
import { persistStore, persistReducer } from 'redux-persist'
import localForage from 'localforage'

import 'bulma/css/bulma.css'
import './index.css'
import ScrollToTop from './components/ScrollToTop'
import App from './containers/App'
import AppGuest from './containers/AppGuest' // TODO: Var based on qparam
import * as serviceWorker from './serviceWorker'
import thunkMiddleware from 'redux-thunk'
import { applyMiddleware, compose, createStore } from 'redux'
import rootReducer from './reducers'
import setUpErrorHandling from './errorHandling'
import toast from './utils/toast'

export const isGuestMode = window.location.pathname === '/guest'

const configureStore = function() {
  const middlewares = [
    thunkMiddleware
    // , api // gebraucht?
  ]

  if (process.env.NODE_ENV !== 'production') {
    const logger = require('redux-logger').default
    middlewares.push(logger)
  }

  const enhancers = [applyMiddleware(...middlewares)]

  const store = createStore(
    persistReducer({ key: 'root', storage: localForage }, rootReducer),
    {},
    compose(...enhancers)
  )

  return store
}

export const store = configureStore()
let rehydratePromise = new Promise(resolve => {
  persistStore(store, null, resolve)
})

const app = (
  <Provider store={store}>
    <Router>
      <ScrollToTop>{isGuestMode ? <AppGuest /> : <App />}</ScrollToTop>
    </Router>
  </Provider>
)

const root = document.getElementById('root')
setUpErrorHandling(root)

const setOnline = function(state) {
  store.dispatch({ type: 'SET_ONLINE', state })
}

Promise.all([rehydratePromise]).then(() => {
  setOnline(navigator.onLine)
  window.addEventListener('offline', function(e) {
    setOnline(false)
  })
  window.addEventListener('online', function(e) {
    setOnline(true)
  })
  renderApp()
  if (!window.navigator.cookieEnabled) {
    toast.warning({
      title: 'Cookies blockiert',
      message:
        'Bitte erlauben Sie Cookies für diese Seite. Ohne die Berechtigung kann es zu Fehlern kommen.',
      timeout: false
    })
  }
})

function renderApp() {
  ReactDOM.render(app, root)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
