const initialState = null

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_CREDS':
    case 'AUTHERROR':
      return null
    case 'SET_USER':
      return Object.assign({}, action.user, {
        currentEmployee: state ? state.currentEmployee : null
      })
    case 'SELECT_EMPLOYEE':
      return Object.assign({}, state, { currentEmployee: action.employee })
    case 'LOGOUT_EMPLOYEE':
      return Object.assign({}, state, { currentEmployee: null })
    case 'DEV_LOGIN':
      return {
        name: '1337',
        employees: [{ id: 9001, name: 'Dummy with 9000 PIN', pin: 9000 }]
      }
    default:
      return state
  }
}
