import React, { Component } from 'react'
import { withRouter } from 'react-router'
import NavLink from './NavLink'
import logo from '../img/ruckerlogo.png'

const responsiveExpandedClass = 'is-active'
const mainNav = 'mainNav'
const navToggle = 'navToggle'

class Navi extends Component {
  toggleMenu() {
    this.toggleMenuState()
  }

  toggleMenuState(state) {
    this.refs[mainNav].classList.toggle(responsiveExpandedClass, state)
    this.refs[navToggle].classList.toggle(responsiveExpandedClass, state)
  }

  componentDidUpdate(prevProps) {
    // Hide the responsive (burger) menu again after switching pages
    if (this.props.location !== prevProps.location) {
      this.toggleMenuState(false)
    }
  }

  render() {
    return (
      <nav
        className="navbar has-shadow"
        id="top"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <NavLink to="/reports" className="navbar-item">
              <img src={logo} alt="Rucker KV-Opti" />
            </NavLink>

            <div
              className="navbar-burger burger"
              onClick={this.toggleMenu.bind(this)}
              ref={navToggle}
              data-target={mainNav}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            ref={mainNav}
            id={mainNav}
            className="navbar-menu"
            style={{ paddingBottom: 0 }}
          >
            <div className="navbar-start"></div>
            {this.props.logout && (
              <div className="navbar-end">
                <div className="navbar-item">
                  <div className="buttons">
                    <button
                      className="button is-light"
                      type="button"
                      onClick={this.props.logout}
                    >
                      Abmelden
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    )
  }
}

export default withRouter(Navi)
