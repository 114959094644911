import React, { Component } from 'react'
import { connect } from 'react-redux'
import { callApiChecked } from '../middleware/api'
import LoadBlock from '../components/LoadBlock'
import Navi from '../components/Navi'
import { Switch, Route, Redirect } from 'react-router-dom'
import ReportSubmit from './ReportSubmit'
import ReportsIndex from './ReportsIndex'
import Modal from 'react-modal'
import toast from '../utils/toast'
import { cleanup as cleanupImageStorage } from '../service/imageStorage'

Modal.setAppElement('#root')

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  checkAuth() {
    if (!this.props.connection) {
      return
    }
    callApiChecked('user')
      .then(data => {
        this.props.dispatch({ type: 'SET_USER', user: data })
        callApiChecked('assessment').then(reportsData =>
          this.props.dispatch({
            type: 'RECEIVE_SERVER_REPORTS',
            data: reportsData,
            user: data
          })
        )
        callApiChecked('config').then(configData =>
          this.props.dispatch({
            type: 'RECEIVE_SERVER_CONFIG',
            data: configData
          })
        )
      })
      .catch(() => {
        this.props.dispatch({ type: 'AUTHERROR' })
      })
  }

  componentDidMount() {
    if (this.props.credentials) {
      this.checkAuth()
    }
    cleanupImageStorage()
    this.setupSpaceTracking()
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId)
  }

  setupSpaceTracking() {
    let intervalId = setInterval(() => {
      navigator.webkitTemporaryStorage.queryUsageAndQuota((using, max) => {
        this.setState({ quota: { using, max } })
      })
    }, 5000)
    this.setState({ intervalId })
  }

  componentDidUpdate() {
    if (this.props.credentials && !this.props.user) {
      this.checkAuth()
    }
  }

  loginInterception() {
    if (!this.props.connection) {
      return (
        <div className="fullpage-modal-wrap">
          <p style={{ margin: 'auto' }}>Keine Internetverbindung</p>
        </div>
      )
    }

    return (
      <div className="fullpage-modal-wrap">
        <form
          style={{ margin: 'auto', minWidth: '250px', padding: '5px' }}
          onSubmit={e => {
            e.preventDefault()
            this.props.dispatch({
              type: 'SET_CREDS',
              data: { user: this.refs.id.value, pass: this.refs.pw.value }
            })
          }}
        >
          <div className="field">
            <h1 className="title">Rucker KV-Opti</h1>
            <h2 className="subtitle">Autohaus Login</h2>
          </div>

          <div className="field">
            <div className="control">
              <input
                className="input"
                type="email"
                ref="id"
                required
                placeholder="E-Mail Adresse"
              />
            </div>
          </div>

          <div className="field">
            <div className="control">
              <input className="input" type="password" ref="pw" required />
            </div>
          </div>

          <div className="field">
            <div className="control">
              <button type="submit" className="button is-link">
                Anmelden
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  }

  closeEmployeePinModal() {
    this.setState({ employeePinCheck: null })
  }
  closeEmployeeCreateModal() {
    this.setState({ showNewEmployeeModal: false, submittingEmployee: false })
  }

  employeeInterception() {
    return (
      <div className="fullpage-modal-wrap">
        <div
          className="list is-hoverable"
          style={{ margin: 'auto', minWidth: '250px', padding: '5px' }}
        >
          {this.props.user.employees.map(emp => (
            <a
              key={emp.id}
              className="list-item"
              onClick={e => {
                e.preventDefault()
                this.setState({ employeePinCheck: emp })
              }}
            >
              {emp.name} {emp.firstname}
            </a>
          ))}
          <a
            className="list-item"
            style={{ display: 'none' }}
            onClick={e => {
              e.preventDefault()
              if (!this.props.connection) {
                toast.warning({
                  title: 'Offline',
                  message: 'Internetverbindung notwendig für diese Aktion'
                })
                return
              }
              this.setState({ showNewEmployeeModal: true })
            }}
          >
            <i>Serviceberater anlegen</i>
          </a>
        </div>
        <Modal
          isOpen={Boolean(this.state.employeePinCheck)}
          style={{
            content: {
              left: '50%',
              right: 'unset',
              bottom: 'unset',
              width: '250px',
              marginLeft: '-125px',
              top: '35%'
            }
          }}
          onRequestClose={this.closeEmployeePinModal.bind(this)}
        >
          <label htmlFor="pin" className="label">
            PIN
          </label>
          <div className="field">
            <div className="control">
              <input
                id="pin"
                type="number"
                autoComplete="off"
                autoFocus
                inputMode="numeric"
                className="input is-large"
                style={{ color: 'lightgray', backgroundColor: 'lightgray' }}
                required
                onChange={e => {
                  if (
                    e.target.value &&
                    e.target.value === this.state.employeePinCheck.pin
                  ) {
                    this.props.dispatch({
                      type: 'SELECT_EMPLOYEE',
                      employee: this.state.employeePinCheck
                    })
                    this.closeEmployeePinModal()
                  }
                }}
              />
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={Boolean(this.state.showNewEmployeeModal)}
          style={{
            content: {
              left: '50%',
              right: 'unset',
              bottom: 'unset',
              width: '300px',
              marginLeft: '-150px',
              top: '20%'
            }
          }}
          onRequestClose={this.closeEmployeeCreateModal.bind(this)}
        >
          <form
            onSubmit={e => {
              e.preventDefault()
              this.setState({ submittingEmployee: true })
              callApiChecked('employee', {
                method: 'POST',
                body: JSON.stringify({
                  name: this.refs.emp_name.value,
                  firstname: this.refs.emp_firstname.value,
                  pin: this.refs.emp_pin.value
                })
              })
                .then(res => {
                  return callApiChecked('user').then(data => {
                    this.props.dispatch({ type: 'SET_USER', user: data })
                  })
                })
                .then(() => {
                  this.closeEmployeeCreateModal()
                })
            }}
          >
            <label htmlFor="emp_name" className="label">
              Name
            </label>
            <div className="field">
              <div className="control">
                <input
                  id="emp_name"
                  type="text"
                  className="input"
                  required
                  ref="emp_name"
                />
              </div>
            </div>
            <label htmlFor="emp_firstname" className="label">
              Vorname
            </label>
            <div className="field">
              <div className="control">
                <input
                  id="emp_firstname"
                  type="text"
                  className="input"
                  required
                  ref="emp_firstname"
                />
              </div>
            </div>
            <label htmlFor="emp_pin" className="label">
              PIN
            </label>
            <div className="field">
              <div className="control">
                <input
                  id="emp_pin"
                  type="number"
                  className="input"
                  required
                  ref="emp_pin"
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button
                  type="submit"
                  className="button is-primary is-fullwidth"
                  disabled={this.state.submittingEmployee}
                >
                  Erstellen
                </button>
              </div>
            </div>
          </form>
        </Modal>
      </div>
    )
  }

  logout() {
    this.props.dispatch({ type: 'LOGOUT_EMPLOYEE' })
    if (this.props.currentReport) {
      this.props.dispatch({
        type: 'SAVE_DRAFT',
        report: this.props.currentReport
      })
      this.props.dispatch({ type: 'CLOSE_REPORT' })
    }
  }

  render() {
    if (!this.props.credentials) {
      return this.loginInterception()
    }

    if (!this.props.user) {
      return <LoadBlock height="100vh" />
    }

    if (!this.props.employee) {
      return this.employeeInterception()
    }

    return (
      <div>
        <header role="banner">
          <Navi user={this.props.user} logout={this.logout.bind(this)} />
        </header>
        <main className="section" style={{ paddingTop: '1rem' }}>
          <div className="container content">
            <Switch>
              <Route path="/reports/submit" component={ReportSubmit} />
              <Route path="/reports" component={ReportsIndex} />
              <Route path="/">
                <Redirect to="/reports" />
              </Route>
            </Switch>
          </div>
        </main>
        <footer role="contentinfo" className="footer">
          <div className="container has-text-right">
            <span>
              {this.props.user.currentEmployee.name +
                ' / ' +
                this.props.user.name}
            </span>
            <br />
            {!this.props.connection ? (
              <small
                className="button is-warning is-small"
                style={{ cursor: 'initial' }}
              >
                <strong>Keine Internetverbindung</strong>
              </small>
            ) : null}
            <br />
            <span style={{ color: 'gray' }}>
              Speichernutzung (MB):{' '}
              {this.state.quota
                ? (this.state.quota.using / 1048576).toFixed(2) +
                  ' / ' +
                  (this.state.quota.max / 1048576).toFixed(2)
                : null}
            </span>
            <br />
            <span style={{ color: 'gray' }}>App-Version: 20110900</span>
          </div>
        </footer>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    credentials: state.credentials,
    user: state.user,
    employee:
      state.user && state.user.currentEmployee
        ? state.user.employees.find(
            emp => emp.id === state.user.currentEmployee.id
          )
        : null,
    connection: state.connection,
    currentReport: state.currentReport
  }
}

export default connect(mapStateToProps, null, null, { pure: false })(App)
