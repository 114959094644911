const initialState = null

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'AUTHERROR':
      return null
    case 'SET_CREDS':
      return action.data
    case 'DEV_LOGIN':
      return { user: '/dev', pass: '1337' }
    default:
      return state
  }
}
