import React, { Component } from 'react'
import { connect } from 'react-redux'
import { callApiChecked } from '../middleware/api'
import Navi from '../components/Navi'
import { Switch, Route, Link } from 'react-router-dom'
import ReportSubmit from './ReportSubmit'
import Modal from 'react-modal'
import { template } from '../reducers/currentReport'
import { cleanup as cleanupImageStorage } from '../service/imageStorage'
import { withRouter } from 'react-router'
import config from '../config.js'

const uuidv1 = require('uuid/v1')

Modal.setAppElement('#root')

class AppGuest extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getConfig() {
    callApiChecked('config').then(configData =>
      this.props.dispatch({
        type: 'RECEIVE_SERVER_CONFIG',
        data: configData
      })
    )
  }

  componentDidMount() {
    this.getConfig()
    cleanupImageStorage()
  }

  render() {
    return (
      <div>
        <header role="banner">
          <Navi logout={undefined} />
        </header>
        <main className="section" style={{ paddingTop: '1rem' }}>
          <div className="container content">
            <Switch>
              <Route path="/reports/submit" component={ReportSubmit} />
              <Route path="/">
                <div>
                  <p>
                    Willkommen bei der Sachverständigen GmbH Fritz Rucker. Über
                    dieses Tool können Sie schnell & unverbindlich ein Gutachten
                    anfragen:
                  </p>
                  <button
                    className="button is-fullwidth is-primary"
                    type="button"
                    onClick={() => {
                      this.props.dispatch({
                        type: 'SET_REPORT',
                        report: Object.assign(
                          {
                            guid: uuidv1(),
                            employee: { id: config.guestempl, name: 'Gast' }
                          },
                          template
                        )
                      })
                      this.props.history.push('/reports/submit')
                      cleanupImageStorage()
                    }}
                  >
                    Neuen Auftrag erfassen
                  </button>
                  <hr />
                  {this.props.currentReport ? (
                    <Link
                      to="/reports/submit"
                      className="button is-fullwidth is-info"
                    >
                      Weiter mit Erfassung von {this.props.currentReport.title}
                    </Link>
                  ) : null}
                </div>
              </Route>
            </Switch>
          </div>
        </main>
        <footer role="contentinfo" className="footer">
          <div className="container has-text-right">
            {!this.props.connection ? (
              <small
                className="button is-warning is-small"
                style={{ cursor: 'initial' }}
              >
                <strong>Keine Internetverbindung</strong>
              </small>
            ) : null}
            <br />
            <span style={{ color: 'gray' }}>App-Version: 20110900</span>
          </div>
        </footer>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    connection: state.connection,
    currentReport: state.currentReport
  }
}

export default connect(mapStateToProps, null, null, { pure: false })(
  withRouter(AppGuest)
)
