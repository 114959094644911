import { combineReducers } from 'redux'

import user from './user'
import credentials from './credentials'
import connection from './connection'
import currentReport from './currentReport'
import reports from './reports'
import config from './config'

export default combineReducers({
  user,
  credentials,
  connection,
  currentReport,
  reports,
  config
})
